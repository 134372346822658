<template>
  <div>
    <transition name="fade">
      <a :href="`#section-home-${this.section + 2}`" v-smooth-scroll="{ duration: 1500 }" class="downBtn" v-if="!lastSection || isMobile">
        <img src="https://ik.imagekit.io/nnol5se8mol/tr:h-50,w-50/downbtn_LWLlPOQ9nq.png">
      </a>
    </transition>
    <div id="container-home">
      <div class="sectionHome" id="section-home-1">
        <div class="parallax">
          <div class="s1-left-content">
            <h2 class="welcomeTitle" v-if="CMS">{{CMS.title_mobile_only[0].text}}</h2>
           <h1 class="bigTextSwiping" :id="'bigTextSwiping' + index" v-for="(item, index) in textTypingAnimationContent" :key="item" v-show="index == textTypingAnimationCurrent">{{item}}</h1>
            <transition name="fade">
              <p v-show="showSubtext" class="subText">{{subtextContent[subtextContentIteration]}}</p>
            </transition>
          </div>
          <!--<img src="./../assets/clement1.png" id="clement1" :class="{hideClem: (section != 0)}">-->
<!--          {hideClem: (section != 0)}-->
          
          <lottie id="clement-anim1" 
                  :class="{hideClem: (section != 0)}"
                   :options="clementAnimOptions"
                  @animCreated="handleClementAnimation"/>


        </div>
      </div>
      <div class="sectionHome" id="section-home-2">
        <div class="parallax">
          <svg version="1.1" id="type-writer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" width="295px" height="380px" viewBox="0 0 295 380" enable-background="new 0 0 295 380" xml:space="preserve">
          <path fill="#353535" d="M26.676,97.369c0,0,2.745,2.421,2.745,10.317h-2.745V97.369z"/>
          <path fill="#353535" d="M268.324,97.369c0,0-2.745,2.421-2.745,10.317h2.745V97.369z"/>
          <rect id="bg2" x="26.676" y="107.686" fill="#444444" width="252.344" height="30.691"/>
          <g id="paper">
            <g>
              <linearGradient id="paper_1_" gradientUnits="userSpaceOnUse" x1="146.4551" y1="217.3887" x2="146.4551" y2="122.72">
                <stop  offset="0" style="stop-color:#FFFFFF"/>
                <stop  offset="1" style="stop-color:#ebebeb"/>
              </linearGradient>
              <path fill="url(#paper_1_)" d="M254.817,119.055H37.79c-7.496,16.316-10.967,45.144-12.506,72.279v66.098h242.342
                V197.16C266.265,168.412,262.854,136.546,254.817,119.055z"/>
            </g>
            <g>
            <path id="heart" fill="#F3987F" d="M157.646,156.068c-4.483,0-8.496,2.011-11.191,5.179c-2.694-3.167-6.707-5.179-11.191-5.179
              c-8.113,0-14.689,6.577-14.689,14.689c0,1.063,0.116,2.099,0.33,3.097c2.258,12.333,25.551,33.041,25.551,33.041
              s23.293-20.708,25.551-33.041c0.215-0.999,0.33-2.034,0.33-3.097C172.336,162.645,165.759,156.068,157.646,156.068z"/>
            </g>
          </g>
          <g id="print-bar">
            <polygon fill="#DCDBD3" points="57.741,123.571 57.741,140.477 59.796,140.477 59.796,129.482 63.908,129.482 63.908,140.477
              65.964,140.477 65.964,123.571 	"/>
            <rect x="43.184" y="116.021" fill="#222223" width="37.339" height="12.002"/>
            <polygon fill="#E5E5D8" points="56.519,121.355 56.519,140.692 59.185,140.692 59.185,129.023 64.52,129.023 64.52,140.692
              67.187,140.692 67.187,121.355 	"/>
            <polygon fill="#353535" points="80.523,116.021 43.184,116.021 44.184,113.688 79.523,113.688 	"/>
          </g>
          <g id="bg1">
            <path fill="#2D2D2D" d="M26.676,138.376c-2.9,0-5.251-9.18-5.251-20.504s2.351-20.503,5.251-20.503V138.376z"/>
            <g>
              <path fill="#2D2D2D" d="M268.324,97.369c2.9,0,5.252,9.18,5.252,20.503s-2.352,20.504-5.252,20.504V97.369z"/>
              <path fill="#444444" d="M273.576,117.872c0-11.324-2.352-20.503-5.252-20.503h21.705v0.232c2.543,1.425,4.5,9.945,4.5,20.271
                c0,10.325-1.957,18.844-4.5,20.271v0.232h-21.705C271.225,138.375,273.576,129.195,273.576,117.872z"/>
            </g>
            <path fill="#444444" d="M21.425,117.872c0-11.324,2.351-20.503,5.251-20.503H4.971v0.232c-2.543,1.426-4.5,9.946-4.5,20.271
              c0,10.325,1.957,18.844,4.5,20.271v0.233h21.706C23.776,138.376,21.425,129.195,21.425,117.872z"/>
            <g>
              <g>
                <g>
                  <g>
                    <path fill="#324D63" d="M71.854,376.998c-4.137,0-7.501-3.365-7.501-7.501v-11.223c0-5.986-2.354-14.732-5.359-19.907
                      l-47.818-82.368c-0.44-0.759-0.712-1.475-0.808-2.104l273.712,0.001c-0.099,0.628-0.369,1.346-0.81,2.104l-47.819,82.368
                      c-3.007,5.18-5.359,13.923-5.359,19.905v11.224c0,4.136-3.365,7.501-7.502,7.501L71.854,376.998L71.854,376.998z"/>
                    <polygon fill="#324D63" points="289.371,138.376 4.971,138.376 7.971,226.451 286.371,226.451 					"/>
                    <path fill="#273D51" d="M286.371,250.895c0,0-2.086-1.523-2.086-12.223c0-10.697,2.086-12.223,2.086-12.223H7.971
                      c0,0,2.084,1.523,2.084,12.223c0,10.697-2.084,12.223-2.084,12.223H286.371z"/>
                    <path fill="#324D63" d="M107.013,135.375c0,16.718,17.363,30.271,38.782,30.271c21.42,0,38.783-13.552,38.783-30.271"/>
                    <path fill="#273D51" d="M192.956,138.376c0,20.609-21.407,37.319-47.815,37.319c-26.407,0-47.814-16.709-47.814-37.319
                      l9.687-3.001c0,16.718,17.363,30.271,38.782,30.271c21.42,0,38.783-13.552,38.783-30.271L192.956,138.376z"/>
                  </g>
                  <circle fill="#273D51" cx="61.279" cy="182.413" r="34.602"/>
                  <circle fill="#273D51" cx="227.559" cy="182.413" r="34.602"/>
                </g>
                <path fill="#262626" d="M230.486,143.81l0.799,4.012c16.018,0.458,28.915,14.634,28.915,31.995
                  c0,10.048-4.256,19.32-11.675,25.434c-0.194,0.162-17.853,14.639-41.223,14.639c-20.461,0-39.135-10.838-55.521-32.217h-0.002
                  c-1.557-2.066-4.324-3.445-7.493-3.445l-0.019,4.301l-0.02-4.301c-3.167,0-5.937,1.379-7.494,3.445h-0.001
                  c-16.388,21.379-35.061,32.217-55.522,32.217c-23.37,0-41.028-14.477-41.222-14.639c-7.419-6.112-11.675-15.385-11.675-25.434
                  c0-17.36,12.898-31.537,28.916-31.995l0.799-4.012c-18.461,0-33.429,16.121-33.429,36.007c0,11.664,5.149,22.033,13.132,28.613
                  c0,0,18.459,15.459,43.479,15.459c16.594,0,36.071-6.809,54.974-29.419c0.989-1.169,3.669-5.835,8.062-5.836
                  c4.391,0.001,7.071,4.667,8.061,5.836c18.904,22.61,38.381,29.419,54.975,29.419c25.021,0,43.479-15.459,43.479-15.459
                  c7.982-6.58,13.134-16.949,13.134-28.613C263.914,159.931,248.948,143.81,230.486,143.81z"/>
                <circle fill="#E5E5D8" cx="144.268" cy="194.135" r="5.292"/>
              </g>
              <path fill="#355170" d="M7.971,250.895c-1.036,1.801-0.82,4.146,0.609,6.608l47.819,82.369c2.731,4.706,4.954,12.961,4.954,18.4
                v11.224c0,5.79,4.711,10.502,10.502,10.502H222.59c5.791,0,10.502-4.71,10.502-10.502v-11.222c0-5.439,2.225-13.692,4.955-18.399
                l47.818-82.37c1.43-2.462,1.646-4.81,0.609-6.608L7.971,250.895z"/>
            </g>
          </g>
          <g id="ink-tape-left">
            <circle fill="#E5E5D8" cx="61.279" cy="178.412" r="34.602"/>
            <g>
              <path fill="#ADD4A4" d="M61.279,151.378c-13.21,0-24.202,9.479-26.56,22.004l-5.076,1v7.03l4.898,1
                c1.933,13.033,13.166,23.033,26.737,23.033c14.931,0,27.035-12.103,27.035-27.033S76.209,151.378,61.279,151.378z"/>
              <circle fill="#97B790" cx="61.277" cy="178.412" r="7.031"/>
            </g>
          </g>
          <g id="ink-tape-right">
            <circle fill="#E5E5D8" cx="227.559" cy="178.412" r="34.602"/>
            <g>
              <path fill="#ADD4A4" d="M227.869,151.378c-13.209,0-24.2,9.479-26.56,22.004l-5.075,1v7.03l4.897,1
                c1.935,13.033,13.166,23.033,26.736,23.033c14.932,0,27.034-12.103,27.034-27.033
                C254.906,163.481,242.803,151.378,227.869,151.378z"/>
              <circle fill="#97B790" cx="227.557" cy="178.412" r="7.031"/>
            </g>
          </g>
          <circle id="key_1" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="45.591" cy="272.039" r="9.17"/>
          <circle id="key_2" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="71.023" cy="272.039" r="9.17"/>
          <circle id="key_3" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="96.455" cy="272.039" r="9.17"/>
          <circle id="key_4" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="121.887" cy="272.039" r="9.17"/>
          <circle id="key_5" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="146.854" cy="272.039" r="9.17"/>
          <circle id="key_6" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="171.762" cy="272.039" r="9.17"/>
          <circle id="key_7" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="197.193" cy="272.039" r="9.17"/>
          <circle id="key_8" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="222.627" cy="272.039" r="9.17"/>
          <circle id="key_9" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="248.059" cy="272.039" r="9.168"/>
          <circle id="key_10" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="58.278" cy="294.06" r="9.169"/>
          <circle id="key_11" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="83.709" cy="294.06" r="9.169"/>
          <circle id="key_12" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="109.142" cy="294.06" r="9.169"/>
          <circle id="key_13" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="134.167" cy="294.06" r="9.169"/>
          <circle id="key_14" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="159.6" cy="294.06" r="9.169"/>
          <circle id="key_15" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="184.449" cy="294.06" r="9.169"/>
          <circle id="key_16" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="209.883" cy="294.06" r="9.169"/>
          <circle id="key_17" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="235.314" cy="294.06" r="9.169"/>
          <circle id="key_18" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="70.965" cy="316.082" r="9.169"/>
          <circle id="key_19" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="96.398" cy="316.082" r="9.169"/>
          <circle id="key_20" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="121.421" cy="316.082" r="9.169"/>
          <circle id="key_21" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="146.854" cy="316.082" r="9.169"/>
          <circle id="key_22" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="172.287" cy="316.082" r="9.169"/>
          <circle id="key_23" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="197.136" cy="316.082" r="9.169"/>
          <circle id="key_24" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="222.57" cy="316.082" r="9.169"/>
          <circle id="key_25" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="83.709" cy="338.102" r="9.17"/>
          <circle id="key_26" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="108.676" cy="338.103" r="9.17"/>
          <circle id="key_27" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="134.108" cy="338.103" r="9.17"/>
          <circle id="key_28" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="159.541" cy="338.103" r="9.17"/>
          <circle id="key_29" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="184.974" cy="338.103" r="9.17"/>
          <circle id="key_30" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" cx="209.883" cy="338.102" r="9.17"/>
          <path id="key_0" fill="#F2F0D7" stroke="#92928D" stroke-miterlimit="10" d="M219.052,368.128c0,1.655-1.343,3-3.001,3H77.541
            c-1.657,0-3-1.345-3-3v-9.438c0-1.658,1.343-3.001,3-3.001h138.51c1.658,0,3.001,1.343,3.001,3.001V368.128L219.052,368.128z"/>
          </svg>
          <div class="overflow-anim-wrapper">
            <h2 :class="{scaleYAnim: (section == 1)}" v-if="CMS">{{CMS.references_title[0].text}}</h2>
          </div>
          <div class="brandingandimg">
            <div class="brandntestimonials">
              <transition name="fade">
                <brandLogos v-if="CMS" :section="section" :isMobile="isMobile" :logoImgs="logoImgs" :logoIndex="logoIndex" :logoOrder="logoOrder" :logo1="logo1" :logo2="logo2" :logo3="logo3" :logo4="logo4" :logo1Img="logo1Img" :logo2Img="logo2Img" :logo3Img="logo3Img" :logo4Img="logo4Img"/>
              </transition>
                <div class="testimonials">
                  <transition name="fade2">
                    <div class="testimonialContent" v-if="showTest && CMS && section == 1">
                      <p>{{this.testimonials[this.currentTest].content}}<br>
                      </p>
                      <span>{{this.testimonials[this.currentTest].author}}</span>
                    </div>
                  </transition>
                </div>
            </div>
            <img src="https://ik.imagekit.io/nnol5se8mol/clement2_0LNwoh3sfc.png" class="animated" :class="{fadeInRight: (section == 1), fadeOutRight: (section != 1)}" id="clement2">
          </div>
          <div style="display: none" class="btn animated delay-1s" @click="$emit('changeRoute', {name: 'portfolio'})" :class="{fadeInUp: (section == 1)}">
            <div></div>
            <span v-if="CMS">{{CMS.references_button_text[0].text}}</span>
          </div>
        </div>
      </div>
      <div class="sectionHome" id="section-home-3">
        <div class="parallax">
          <div class="overflow-anim-wrapper">
            <h2 :class="{scaleYAnim: (section == 2)}" v-if="CMS">{{CMS.services_title[0].text}}</h2>
          </div>
          <div class="service-wrapper" @mouseleave="normalizeService()">
            <div class="service" v-for="(service, index) in services" :key="index" :id="'service' + index" @mouseover="expandService(index)" :class="{hideService: ((!showAll && index !== indexSpecial) || (isMobile && !showAll))}" :style="{transform: translateService(index)}">
              <img :src="servicesFromStore[index].imgTiny || servicesFromStore[index].imgBg || servicesFromStore[index].img"
                   class="animated"
                   :class="{zoomIn: (section == 2), zoomOut: (section != 2)}">
              <div class="overflow-service-par">
                <h3 :class="{scaleYAnim: (section == 2)}">{{service.title}}</h3>
              </div>
            </div>
            <div class="service-part-text animated" @click="normalizeService2()" :class="{fadeInDown: (!showAll || !mobileSwitchBetween) && !isMobile, fadeOutDown: (showAll || mobileSwitchBetween) && !isMobile, bounceInLeft: (!showAll || !mobileSwitchBetween) && isMobile, fadeOutRight: (showAll || mobileSwitchBetween) && isMobile}">
              <img v-show="isMobile" :src="servicesFromStore[indexSpecial].imgTiny || servicesFromStore[indexSpecial].imgBg || servicesFromStore[indexSpecial].img">
              <p v-if="services.length > 0">{{services[indexSpecial].content}}</p>
            </div>
          </div>
          <!-- Nik imagekit -->
         <!-- <div id="clement3" class="animated"
                :class="{fadeInRight: (section == 2), fadeOutRight: (section != 2)}"><img src="./../assets/clement3
                .png"></div>-->
          <div id="clement3" class="animated"
               :class="{fadeInRight: (section == 2), fadeOutRight: (section != 2)}"><img
                  src="https://ik.imagekit.io/nnol5se8mol/tr:w-576,h-360/clement3_QY4csjdoV.png"></div>
          <div class="btn animated" @click="$emit('changeRoute', {name: 'services'})" :class="{fadeInUp: (section == 2), fadeOutDown: (section != 2)}">
            <div></div>
            <span v-if="CMS">{{CMS.services_button_text[0].text}}</span>
          </div>
        </div>
      </div>
<!--
      <div class="sectionHome" id="section-home-4">
        <div class="parallax">
          <div id="s4-top">
            <div class="s4-top-wrapper">
              <div class="overflow-anim-wrapper">
                <h1 :class="{scaleYAnim: (section == 3)}" v-if="CMS">{{CMS.contact_title[0].text}}</h1>
              </div>
              <div class="btn animated" @click="$store.state.modalOpened = true" :class="{fadeInUp: (section == 3), fadeOutDown: (section != 3)}">
                <div></div>
                <span v-if="CMS">{{CMS.contact_button_text[0].text}}</span>
              </div>
            </div>
            <div id="clement4" class="animated" :class="{fadeInRight: (section == 3), fadeOutRight: (section != 3)}"><img src="https://ik.imagekit.io/nnol5se8mol/clement4_37xq-iujW.png"></div>
          </div>
-->
<!--          <footerComp/>-->
<!--        </div>-->
<!--      </div>-->
      <footerComp/>



    </div>
  </div>
</template>

<style lang="sass">
#type-writer
  position: absolute
  bottom: 30px
  left: 20px
  @media (max-width: 1300px)
    display: none

#heart
  opacity: 0

.hideService
  transform: translateY(30px)
  opacity: 0
  pointer-events: none

.fade-enter-active
  transform: translate(0, 30px)
  opacity: 0
  @media (max-width: 1370px)
   // opacity: 1 !important

.fade-enter-active
  transition: all 0.9s 0.8s

.fade-leave-active
  transition: all 0.7s

.fade-leave-to
  opacity: 0
  @media (max-width: 1370px)
    //opacity: 1 !important

.fade-enter-to
  opacity: 1
  transform: translate(0, 0)

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

#container-home
  background-color: $color5
  width: 100vw
  position: absolute
  left: 0
  top: 0
  .sectionHome
    width: 100vw
    height: 100vh
    overflow: hidden

#section-home-4
  background-color: $color2
  .parallax
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    overflow: hidden
    #s4-top
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 1100px)
        flex-direction: column
      .s4-top-wrapper
        @media (max-width: 600px) and (orientation: portrait)
          position: relative
          bottom: 100px
        @media (orientation: landscape)
           position: relative
           top: 15px
        .overflow-anim-wrapper
          position: relative
          overflow: hidden
          padding-top: 30vh
          @media (orientation: landscape) and (max-width: 1100px)
           padding-top: 0 !important
          @media (max-width: 600px)
            padding-top: 42vh
          h1
            margin: 0
            transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
            transform: translateY(100%)
            @media (max-width: 600px)
              font-size: 20px
        .btn
          opacity: 0
          @media (max-width: 1370px)
            opacity: 1 !important
          margin-top: 40px
          @media (min-width: 1100px)
            margin-left: 0
          @media (max-width: 600px)
            margin-top: 20px
          &:hover
            span
              color: $color2
      #clement4
        @media (max-width: 1100px)
          display: none
        @media (min-width: 1100px)
          position: absolute
          right: 10vw
        @media (min-width: 1100px) and (max-width: 1400px)
          right: 2vw
        img
          width: 300px
          height: 600px
          position: relative
          @media (min-width: 1100px)
            top: 220px
          @media (min-width: 1100px) and (max-width: 1400px)
            top: 130px
            width: 200px
            height: 400px

#section-home-3
  background-color: $color1
  .parallax
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    overflow: hidden
    .overflow-anim-wrapper
      position: relative
      overflow: hidden
      @media (orientation: landscape) and (max-width: 1320px)
        top: 15vh
        height: 80px
      h2
        transition: all 1.2s cubic-bezier(.165,.84,.44,1) .2s
        transform: translateY(100%)
        opacity: 0
        margin: 0
        padding: 2vh 0
        @media (max-width: 600px)
          font-size: 20px
          padding-top: 5vh
        @media (min-width: 600px) and (max-width: 1100px)
          font-size: 25px
        @media (orientation: landscape) and (max-width: 1320px)
          position: relative
          bottom: 12px
    .service-wrapper
      position: relative
      z-index: 200
      @media (max-width: 1100px)
        width: 100vw
        height: 80vw
      @media (orientation: landscape) and (max-width: 1320px)
        top: 7vh
        margin-top: 8vh
      .service-part-text
        position: absolute
        right: 0
        left: 0
        bottom: 0
        top: 0
        margin: auto
        width: 50%
        height: 50px
        @media (min-width: 1100px)
          p
            position: relative
            bottom: 30px
        @media (min-width: 600px) and (max-width: 1100px)
          p
            font-size: 22px
        @media (max-width: 1100px)
          top: 10vh
          width: 90%
          height: 100vh
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
        img
          width: 100px
          height: 100px
        @media (orientation: landscape) and (max-width: 1320px)
          p
            font-size: 15px
          img
            width: 60px
            height: 60px
          top: 3vh !important
      @media (max-width: 1600px) and (min-width: 1100px)
        width: 60vw
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: center
      @media (max-width: 1100px) and (min-width: 600px)
        margin-bottom: 50px
      @media (max-width: 1100px)
        margin-bottom: 20px
      img
          width: 80%
          height: 80%
      .service
        transition: all 0.5s ease-in-out
        margin: 1.5vw
        height: 9vw
        width: 9vw
        @media (max-width: 1100px)
          width: 20vw
          height: 20vw
          margin: 5vw
        @media (orientation: landscape) and (max-width: 1120px)
          width: 10vw
          height: 10vw
        .overflow-service-par
          overflow: hidden
          width: 120%
          position: relative
          right: 10%
          font-size: 14px
          @media (max-width: 600px)
            font-size: 12px
            margin: 0
          @media (orientation: landscape) and (max-width: 1320px)
            width: 200%
            position: relative
            right: 48%
          @media (orientation: landscape) and (max-width: 1320px)
            font-size: 10px
          h3
            transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
            transform: translateY(200%)
    .btn
      @media (orientation: landscape) and (max-width: 1320px)
        margin-top: 0 !important
        height: 130px !important
        top: 10px
      opacity: 1
      position: relative
      z-index: 300
      margin-top: 50px
      @media (max-width: 600px) and (orientation: portrait)
        margin-top: 30px !important
      @media (min-width: 600px) and (max-width: 1100px)
        margin-top: -30px !important
      @media (max-width: 1300px) and (min-width: 1100px)
        top: 50px
        height: 50px !important
      &:hover
        span
          color: $color1
    #clement3
      position: absolute
      width: 40vw
      height: 25vw
      right: -10vw
      @media (max-width: 1350px)
        display: none
      img
        width: 100%
        height: 100%
        position: relative
        top: 30vh

#section-home-2
  background-color: $color5
  .parallax
    position: relative
    overflow: hidden
    display: flex
    flex-direction: column
    justify-content: center
    height: 100vh
    width: 100vw
    .overflow-anim-wrapper
      position: relative
      overflow: hidden
      margin: 0 auto
      h2
        transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
        transform: translateY(100%)
        margin: 0
        font-size: 25px
        @media (max-width: 600px)
          font-size: 18px
          padding: 10vh 10px 25px 10px
        @media (orientation: landscape) and (max-width: 1320px)
          margin: 0 10px
    .btn
      @media (max-width: 600px)
        margin-top: 10px
      &:hover
        span
          color: $color5
    .brandingandimg
      position: relative
      display: flex
      justify-content: center
      .brandntestimonials
        display: flex
        flex-direction: column
        align-items: center
        position: relative
        .testimonials
          position: relative
          margin-top: 40px
          width: 400px
          @media (max-width: 1100px)
            width: 300px
            font-size: 13px
          @media (max-width: 350px)
            width: 80vw
          .testimonialContent
            position: absolute
            top: 30px
            transform: translateY(-50px)
            flex-direction: column
            align-items: center
            p
              font-style: italic
              font-size: 1.3rem
              @media (max-width: 1100px), (max-height: 750px)
                font-size: 12px
            span
              text-decoration: underline
              font-weight: bold
        div
          display: flex
      #clement2
        width: 400px
        height: 756px
        position: absolute
        right: 10vw
        top: -17vh
        @media (max-width: 1500px)
          top: -50px
          right: 5vw
        @media (max-width: 1300px) and (min-width: 600px)
          top: 12vh
          right: 5vw
          width: 200px
          height: 400px
        @media (max-width: 600px)
          display: none

//#clement-anim2
  transition: all 1s ease-out
  @media (max-width: 330px)
    height: 70vw!important
  //margin: 0 auto 0 auto!important
  //overflow: visible
    height: 70vw!important

#section-home-1
  background-color: $color4
  .parallax
    display: flex
    justify-content: space-around
    height: 100vh
    width: 100vw
    position: relative
    overflow: hidden

    #clement-anim1 svg
      transition: all 1s ease-out
      //iphone media queries to fix glitches
      @media (max-width: 374px) and (orientation: portrait)
        height: 95vw!important
      @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (orientation : portrait)
        height: 75%!important
      @media (min-width: 800px) and (min-height: 550px)
        z-index: 20
        position: relative
        left: 30vw
      @media (min-width: 800px) and (min-height: 550px) and (orientation: portrait)
        z-index: 20
        position: relative
        left: 0vw
        width: 100%
        //height: 80vw!important
        //bottom: 15vw
        //top: 15vh
      @media (min-width: 1319px) and (max-width: 1321px)
        display: none
      @media (max-width: 600px)
        width: auto
        height: 100vw
        margin: 0 auto 45% auto
      @media (max-width: 1320px) and (min-width: 600px)
        margin: 0 auto 15% auto
        width: auto
        height: 100vw
      @media (min-width: 800px) and (max-width: 1320px) and (max-height: 1000px)
        width: auto
        height: 600px
      @media (orientation: landscape) and (max-width: 1320px)
        width: auto
        height: 400px
        position: relative
        right: 7vw
      //@media (orientation: landscape) and (max-width: 1320px) and (min-height: 400px) and (max-height: 1099px)
        width: auto !important
        height: 800px !important
        position: relative !important
        margin-right: 10vw !important
      @media (orientation: landscape) and (max-width: 1300px) and (min-width: 600px)
        position: relative
        left: 10vw
        //top: 50px
        //width: 300px !important
        //height: 600px !important
        /*IPAD PRO - @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) */
      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)
        position: relative
        top: 50px
        //width: 600px
        //height: 1200px
        left: 0vw
      /* Landscape */
      @media only screen and (min-width: 1025px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)
        //left: 30vw
        /*top: auto*/
      @media only screen and (min-width: 1025px) and (max-width: 1399px) and (orientation: landscape)
       // left: 0vw
      /*top: auto*/
      @media only screen and (min-width: 1322px)
        left: 30vw
      @media only screen and (min-width: 1400px)
        top: auto
    /*IPAD PRO END*/
    @media (max-width: 1320px) and (orientation: portrait)
      //flex-direction: column
      //justify-content: center
      display: block

    //#clement1
      transition: all 1s ease-out
      @media (min-width: 800px) and (min-height: 550px)
        z-index: 20
        position: relative
        left: 10vw
        width: auto
        height: 100%
      @media (min-width: 1319px) and (max-width: 1321px)
        display: none
      @media (max-width: 600px)
        width: auto
        height: 100vw
        margin: 0 auto 45% auto
      @media (max-width: 1320px) and (min-width: 600px)
        margin: 0 auto 15% auto
        width: auto
        height: 100vw
      @media (min-width: 800px) and (max-width: 1320px) and (max-height: 1000px)
        width: auto
        height: 600px
      @media (orientation: landscape) and (max-width: 1320px)
        width: auto
        height: 400px
        position: relative
        right: 7vw
      @media (orientation: landscape) and (max-width: 1320px) and (min-height: 400px) and (max-height: 1099px)
        width: auto !important
        height: 800px !important
        position: relative !important
        margin-right: 10vw !important
      @media (max-width: 1300px) and (min-width: 600px)
        position: relative
        top: 50px
        width: 300px !important
        height: 600px !important

    .s1-left-content
      .welcomeTitle
        @media (min-width: 1320px)
          display: none
        @media (max-width: 1320px)
         margin-top: 90px
         font-size: 3.8vh
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 30px
        @media (orientation: landscape) and (max-width: 1320px) and (min-height: 400px) and (max-height: 1099px)
          font-size: 40px !important
          margin-top: 40vh !important
          width: 450px
      .subText
        position: absolute
        right: 30vw
        bottom: 17vh
        margin: auto
        width: 24vw
        height: 20vh
        font-size: 35px
        @media (orientation: landscape) and (min-width: 1200px) and (max-width: 1319px)
          left: 35vw!important
          right: 0!important
        @media (min-width: 1320px) and (max-width: 1500px)
          right: 35vw !important
        @media (orientation: landscape) and (min-height: 1300px)
          font-size: 3.5rem
        @media (orientation: landscape) and (min-width: 240px) and (max-width: 820px)
          display: none
        @media (max-width: 1320px) and (orientation: portrait)
          display: none

      h2
        margin: 150px 0 0 0
        @media (max-width: 600px)
          font-size: 25px
        @media (min-width: 600px)
          font-size: 45px
      @media (min-width: 1320px)
        text-align: left
        margin: 20% 10% 0 0
      @media (orientation: landscape) and (max-width: 1320px)
        text-align: left !important
        margin: 0 5% !important
        position: relative
        bottom: 10%
      @media (max-width: 1320px) and (orientation: portrait)
        text-align: center
      .bigTextSwiping
        @media (min-width: 1320px)
          position: absolute
          left: 10vw
          top: 35vh
          width: 62vw
        margin-top: 0
        pointer-events: none
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -o-user-select: none
        user-select: none
        @media (min-width: 600px) and (max-width: 1320px)
          font-size: 3.5vh
        @media (max-width: 600px)
          font-size: 22px
        @media (min-width: 1320px)
          font-size: 5vw
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 20px
          margin: auto
        @media (orientation: landscape) and (max-width: 1320px) and (min-height: 400px) and (max-height: 1099px)
          font-size: 30px

.hideClem
  transform: translateX(800px)

@keyframes slide-in-right
  from
    transform: translateX(300px)
  to
    opacity: 1
    transform: translateX(0px)
</style>

<script>
import {TimelineMax, TweenMax, Back} from 'gsap'
import {SplitText} from 'gsap/SplitText'
import Lottie from 'vue-lottie'
//import * as clementAnimationData from './../assets/clement-anim-700'
//import * as clementAnimationData from './../assets/clement-anim-fixed'
import * as clementAnimationData from './../assets/clement-anim-190'


//Lottie.setLocationHref(window.location.href);
//Lottie.setLocationHref(document.location.href);
export default {
  //VueMeta
  metaInfo: {
    title: 'Hear Ye Welcome to our Marketing, Web, Mobile and Shopify Home',
    meta: [
      {
        //vmid: 'description',
        name: 'description',
        content:
                'Jaw-dropping Web Experiences, Mobile and Shopify Apps Products & Services. eCommerce Migration, Retail On-boarding, Shopify Retail POS and App Development, Shopify SEO, Marketing and Consulting. Serving Canada, USA, Europe and the World. All inclusive white glove service will migrate and deploy your eCommerce site onto the Shopify platform in record time.'
      }
    ]
  },
  components: {
    'lottie': Lottie
  },

  data() {
    return {
      clementAnimOptions:{animationData: clementAnimationData.default, renderer: 'svg', loop: true, autoplay: true,
        rendererSettings: {
          hideOnTransparent: true,
          progressiveLoad: true,
          //preserveAspectRatio: 'xMinYMin slice'
      }
      },
      CMS: null,
      showTest: true,
      currentTest: 0,
      testimonials: [],
      services: [],
      serviceSectionTitle: null,
      mobileSwitchBetween: false,
      showAll: true,
      indexSpecial: 0,
      textTypingAnimationCurrent: 0,
      textTypingAnimationContent: [],
      subtextContent: [],
      showSubtext: true,
      subtextContentIteration: 0,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      // For logo component
      logoImgs: [require('@/assets/client1.png'), require('@/assets/client2.png'), require('@/assets/client3.png'), require('@/assets/client4.png'), require('@/assets/client5.png'), require('@/assets/client6.png'), require('@/assets/client7.png'), require('@/assets/client8.png')],
      logoIndex: 4,
      logoOrder: 2,
      logo1: true,
      logo1Img: '',
      logo2: true,
      logo2Img: '',
      logo3: true,
      logo3Img: '',
      logo4: true,
      logo4Img: '',
      showWriter: true,
    }
  },

  watch: {
    section() {
      if(this.section == 1 && this.showWriter) {
      this.showWriter = false

      let el = {
        svg: "#type-writer",
        inkR: "#ink-tape-right",
        inkL: "#ink-tape-left",
        keys: "[id^='key_']",
        paper: "#paper",
        printBar: "#print-bar",
        spaceBar: "#key_0",
        heart: "#heart",
      }

        // Repeat
        var tlTypeWriter = new TimelineMax({repeat:-1, yoyo: true, repeatDelay:5})

        //printBar
        TweenMax.to(el.printBar, 1, {
          x:70,
        });
        //ink wheel
        tlTypeWriter.to(el.inkR +","+ el.inkL, 1.1, {
          transformOrigin:"center",
          rotation:360,
          repeat:1,
          onRepeat:paperFull,
        });
        //keys
        TweenMax.to(el.spaceBar, 1, {
          scale:.96,
          transformOrigin:"center",
        });
        //paper
        TweenMax.to(el.paper, 1, {
          y:-50,
        });

        // eslint-disable-next-line no-inner-declarations
        function paperFull(){

        TweenMax.to(el.paper, 1, {
            y:-100,
          });
          TweenMax.to(el.printBar, .5, {
            x:70,
            //scale:-3
          });
          TweenMax.to(el.heart, .5, {
            opacity:1,
          });
          TweenMax.fromTo(el.heart, 0.9, {
            //immediateRender:false,
          scale:1 //	opacity:1,
          },{
            scale:1.2,
            repeat: 20,
            yoyo: true
          });
          TweenMax.to(el.printBar, .5, {
            scale:1
          });
          TweenMax.to(el.printBar, 1, {
            x:160,
          });

        }
      }
    },
    CMS() {
      this.CMS.body[0].items.forEach(item => {
        this.textTypingAnimationContent.push(item.heading_content[0].text)
      })

      this.CMS.body[1].items.forEach(item => {
        this.subtextContent.push(item.subheading_content[0].text.replace(/\s+/g, ' '))
      })

      this.CMS.body1[0].items.forEach(item => {
        this.services.push({
          title: item.title[0].text.replace(/\s+/g, ' '),
          content: item.content[0].text.replace(/\s+/g, ' ')
        })
      })

      this.logoImgs = []
      this.CMS.body2[0].items.forEach(item => {
        this.logoImgs.push({
          logoImg: item.logo.url,
          linkToWebsite: item.link_to_website.url
        })
      })

      this.CMS.body2[1].items.forEach(item => {
        this.testimonials.push({
          content: item.text[0].text.replace(/\s+/g, ' '),
          author: item.author[0].text.replace(/\s+/g, ' ')
        })
      })

      setTimeout(() => {
        let timelines = []
        this.textTypingAnimationContent.forEach(() => {
          var timeline =  new TimelineMax,
          homeSplitText = new SplitText(`#bigTextSwiping${timelines.length}`, {type:"chars"}),
          chars = homeSplitText.chars;
          //timeline.staggerFrom(chars, 0.5, {opacity:0, y:25, transformOrigin:"0% 50% -50",  ease:Back.linear}, 0.04, "+=0");
        timeline.staggerFromTo(chars, 0.6, {opacity:0, scale:0, y:80, rotationX:180,
          transformOrigin:"0% 50% -50", ease:Back.easeOut }, { opacity:1, scale:1, y:0, rotationX:0 }, 0.03, '-=0.3');
          //timeline.staggerFrom(chars, 1.2, {opacity:0, scale:0, y:30, transformOrigin:"0% 70%", ease:Back.linear}, 0.08, "-=0.1");
          timelines.push(timeline)
        })

        let textAnim = setInterval(() => {
            timelines[this.textTypingAnimationCurrent].reverse()
            this.showSubtext = false
            setTimeout(() => {
              this.subtextContentIteration = this.subtextContentIteration == this.CMS.body[1].items.length - 1 ? 0 : this.subtextContentIteration + 1
              this.showSubtext = true
              this.textTypingAnimationCurrent = this.textTypingAnimationCurrent < 4 ? this.textTypingAnimationCurrent + 1 : 0
              timelines[this.textTypingAnimationCurrent].restart()
            }, 1300)
            if(this.$route.name !== 'home') {
              clearInterval(textAnim)
            }
        }, 8000) //timer
      }, 1000)
    },
  },

  computed: {
    section() {
      return this.$store.state.sectionSpecial
    },
    servicesFromStore() {
      return this.$store.state.services
    },
    lastSection() {
      return this.section == 0 ? false : this.section < document.querySelectorAll('.sectionHome').length - 1 ? false : true
    }
  },

  methods: {
    handleClementAnimation(anim) {
      this.anim = anim;
    },
    translateService(index) {
      return (!this.showAll && index === this.indexSpecial && !this.isMobile) ? 'translateX(-'+index*12+'vw) scale(1.1)' : 'translateX(0)'
    },

    expandService(index) {
      this.showAll = false
      this.indexSpecial = index
      if(!this.intervalStarted) {
        this.intervalStarted = true
        let indexInterval = setInterval(() => {
          this.mobileSwitchBetween = true
          setTimeout(() => {
            this.indexSpecial = this.indexSpecial === this.services.length - 1 ? 0 : this.indexSpecial + 1
            this.mobileSwitchBetween = false
          }, 500)
          if(this.showAll) {
            clearInterval(indexInterval)
            this.intervalStarted = false
          }
        }, 6000)
      }
    },

    normalizeService() {
      if(!this.isMobile) {
        this.showAll = true
      }
    },

    normalizeService2() {
      setTimeout(() => {
        if(this.isMobile && !this.showAll) {
          this.showAll = true
        }
      }, 50)
    }
  },

  beforeDestroy() {
    this.showAll = true
  },

  destroyed() {
    document.body.style.height = "1000vh"
  },

  beforeCreate() {
    // CMS DEMONSTRATION
    this.$prismic.client.getSingle('homepage')
    .then(doc => {
      this.CMS = doc.data
    })
    //
  },

  mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)

    let testInt2 = setInterval(() => {
      this.showTest = true
      setTimeout(() => {
        this.showTest = false
      }, 9000)
      setTimeout(() => {
        if(this.testimonials)
          this.currentTest = this.currentTest >= this.testimonials.length - 1 ? 0 : this.currentTest + 1
      }, 10000)
      if(this.$route.name !== 'home') {
        clearInterval(testInt2)
      }
    }, 12000)
  },

  created() {
    let logoInterval = setInterval(() => {
        switch(this.logoOrder) {
            case 1:
                this.logo1 = !this.logo1
                if(!this.logo1)
                    this.logo1Img = this.logoImgs[this.logoIndex]
                break;
            case 2:
                this.logo2 = !this.logo2
                if(!this.logo2)
                    this.logo2Img = this.logoImgs[this.logoIndex]
                break;
            case 3:
                this.logo3 = !this.logo3
                if(!this.logo3)
                    this.logo3Img = this.logoImgs[this.logoIndex]
                break;
            case 4:
                this.logo4 = !this.logo4
                if(!this.logo4)
                    this.logo4Img = this.logoImgs[this.logoIndex]
                break;
            }
            this.logoIndex = this.logoIndex >= this.logoImgs.length - 1 ? 4 : this.logoIndex + 1
            this.logoOrder = this.logoOrder == 2 ? 4 : (this.logoIndex == 4 ? 1 : (this.logoOrder == 1 ? 3 : 2))
            if(this.$route.name != 'home') {
              clearInterval(logoInterval)
            }
    }, 5000)
  },
}
</script>

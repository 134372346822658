import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import footerComp from './components/footer.vue'
import brandLogos from './components/brandLogos.vue'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueScrollTo from 'vue-scrollto'
import PrismicVue from 'prismic-vue';
import linkResolver from './link-resolver';
//VueMeta
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";

Vue.use(vueSmoothScroll);
Vue.use(VueScrollTo, {
  cancelable: false,
  easing: "easeIn",
  onStart: ()=>{
    // console.log('start');
  },
});

// GTAG
Vue.use(VueGtag, {config: { id: "UA-89639398-2" }}, router)

// Add this before the new Vue instance
Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver
});

Vue.config.productionTip = false
Vue.component('footerComp', footerComp)
Vue.component('brandLogos', brandLogos)
//VueMeta
//Vue.use(VueMeta)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
new Vue({
  data() {
    return {
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

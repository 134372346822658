<template>
    <div id="footer">
        <div class="ftop"> 
            <div class="ftop1">
            <h2>{{footerData.title}}</h2>
            <div class="icons">
                <a href=""><img src="https://ik.imagekit.io/nnol5se8mol/tr:w-30,h-30/fb_yWKJBfdkq.png" alt="Facebook Icon"></a>
                <!-- Nik image cloudinary -->
                <!--<a href=""><img src="@/assets/tw.png" alt="Twitter Icon"></a>-->
                <a href=""><img src="https://ik.imagekit.io/nnol5se8mol/tr:w-30,h-30/tw_a_hUofGso.png" alt="Twitter Icon"></a>
                <!--<a href=""><img src="@/assets/in.png"></a>
                <a href=""><img src="@/assets/li.png"></a>-->
            </div>
            </div>
            <div class="ftop2">
                <!--Nik imagekit -->
                <div><a :href="`tel:${this.phoneWithNoSpaces}`"><img
                        src="https://ik.imagekit.io/nnol5se8mol/tr:w-30,h-30/phone_WO27u_kCVw.png"
                                                                     alt="phone"><span>{{footerData
                    .phone}}</span></a></div>
                <!--Nik imagekit-->

                <!--<div><a :href="`mailto:${this.footerData.email}`"><img src="@/assets/email.png"
                                                                       alt="email"><span>{{footerData
                    .email}}</span></a></div>-->
                <div><a :href="`mailto:${this.footerData.email}`"><img
                        src="https://ik.imagekit.io/nnol5se8mol/tr:w-30,h-30/email_VxP6ZofGw.png"
                                                                       alt="email"><span>{{footerData
                    .email}}</span></a></div>
            </div>
            <div class="ftop3 btn" @click="$store.state.modalOpened = true">
                <div></div>
                <span>{{footerData.btn}}</span>
            </div>
        </div>
        <div class="fbottom">
            <hr>
            <small>{{footerData.copyright}}</small>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        footerData() {
            return this.$store.state.footerData
        },
        phoneWithNoSpaces() {
            return this.$store.state.footerData.phone.replace(/ /g, "")
        }
    }
}
</script>
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('./views/Services.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('./views/Blog.vue')
    },
    {
      path: '/post',
      name: 'post',
      component: () => import('./views/Post.vue')
    },
    {
      path: '/work',
      name: 'portfolio',
      component: () => import('./views/Portfolio.vue')
    },
    {
      path: '/interhigh-school',
      name: 'interhigh-school',
      component: () => import('./views/portfolioProject.vue'),
    },
    {
      path: '/data-migration',
      name: 'ServiceDataMigration',
      component: () => import('./views/ServiceDataMigration.vue')
    },
    {
      path: '/retail-onboarding',
      name: 'ServiceShopifyRetailOnboarding',
      component: () => import('./views/ServiceShopifyRetailOnboarding.vue')
    },
    {
      path: '/web-services-wow',
      name: 'ServiceShopifyOnlineServices',
      component: () => import('./views/ServiceShopifyOnlineServices.vue')
    },
    {
      path: '/app-development',
      name: 'ServiceAppDevelopment',
      component: () => import('./views/ServiceAppDevelopment.vue')
    },
    {
      path: '/marketing-and-consulting',
      name: 'ServiceMarketing',
      component: () => import('./views/ServiceMarketing.vue')
    },
    { path: '/404', component: () => import('./views/NotFound.vue') },
    { path: '*', redirect: '/404' },
  ]
})
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    section: 0,
    sectionSpecial: 0,
    scrollInProgress: false,
    lastScrolltop: 0,
    modalOpened: false,
    badgeShow: false,
    offsetTop: true,
    notfoundtext: 'Golly Old Chap, I think we\'re lost...',
    service: {
      contactBtn: 'Pricing',
      contactPar: 'Contact Clement\'s splendiforous email service for a free quote. Commitment and spam free. Giddy up! :)'
    },
    currentPost: 0,
    services: [
      {
        img: require('./assets/database.png'),
        imgTiny: require('./assets/db_tiny.png'),
        //backgroundColor: '#282828',
        backgroundColor: '#3cb778',
        routeName: 'ServiceDataMigration'
      },
      {
        img: require('./assets/retail.png'),
        imgTiny: require('./assets/retail_tiny.png'),
        // backgroundColor: '#393939',
        backgroundColor: '#15a9de',
        routeName: 'ServiceShopifyRetailOnboarding'
      },
      {
        img: require('./assets/website.png'),
        imgBg: require('./assets/websiteBg.png'),
        imgTiny: require('./assets/web_tiny.png'),
        backgroundColor: '#e07e62',
        routeName: 'ServiceShopifyOnlineServices'
      },
      {
        img: require('./assets/webapp.png'),
        imgBg: require('./assets/webappBg.png'),
        imgTiny: require('./assets/app_tiny.png'),
        backgroundColor: '#1781dc',
        routeName: 'ServiceAppDevelopment'
      },
      {
        img: require('./assets/marketing.png'),
        imgTiny: require('./assets/marketing_tiny.png'),
        /*backgroundColor: '#ebeb00',*/
        //backgroundColor: '#ebc318',
        //backgroundColor: '#ffe814',
        backgroundColor: '#15a9de',
        routeName: 'ServiceMarketing'
      }
    ],
    meetClement: [
      {
        headline: "Clement Labs",
        spans: ["The fruit of labour for one Clement Appleton Mountbatten III was Clement Labs. Founded in a township of the New Colonies called Toronto, Canada. A place considered fondly by many a traveller as a good spot to raise a family and one where you’re less likely to be savaged by tigers. Jolly Good!", "The Lab allowed Clement to flourish and hone his technical talents now twice as madenning and three times more likely to cause a random discombobulation of the space time continuum."],
        button: 'I Want To Read It Again',
        bigImage: require('./assets/clement1.png')
      },
      {
        headline: "The Youth",
        spans: ["Clement expressed a keen interest in the Sciences at a young age, winning several awards and in the process being linked to the disappearance of several of his non-award winning competitors during a period known as the Dark Times at University.", "After his formal education had concluded and being the recipient of several awards including 'Most Likely To Become a Mad Scientist', Clement headed off into the world at large to make his white glove padded mark..."],
        button: 'The Lab',
        bigImage: require('./assets/clement5-1600-min.png')
      },
      {
        headline: "The Beginning",
        spans: ["Clement was born sometime between 1940 and 1952. His precise birth is unrecorded because a gentleman should never reveal his age and he was not one to start this trend.", "His family had resided in Greater Shropshire-upon-Cheshire, a place that he would call home until his young steed called Penance ran off into a neighbouring county, rather unbecomingly, with Clement at the mount. He would not return home for 20 years for it would be ungentlemanly to admit culpability in becoming lost."],
        button: 'Youthful Exuberance',
        bigImage: require('./assets/clement1.png')
      }
    ],
    dataMigration: {
      title: 'Data Migration',
      title1: 'Migrating to Shopify the relaxed way',
      subtitles: ['Data imports can be stressful, not to mention time-consuming and rather awkward on occasion. We' +
      ' have trained our data reflexes and migration muscles for years. Let us do the heavy lifting for you.', 'Clement will simplify the migration process and get through it all with the stiffest upper lip, allowing you to focus on important things your business, hair or whatever takes your fancy.', 'Bulk import orders, products, gift cards and customers from any platform or shopping cart to Shopify. You’ll never need to check your old shopping cart for orders again. Just forget about it. How refreshing!'],
      title2: 'Your Data Migration Shopping List:',
      features: [
        {
          title: 'Any orders, any format.',
          content: 'Easily import your data. Whether your orders are from Online, POS or come in on a paper napkin.' +
              ' Clement has you' +
              ' covered.'
        },
        {
          title: 'Customer Profiles.',
          content: 'You’ve spent time and money building a customer list. Clement will automatically create and update customer profiles for you, integrating all of your fields, notes and data into Shopify like strawberries and cream.'
        },
        {
          title: 'Products.',
          content: 'What’s a Shopify site without products? Clement will help you import your products from places far and wide.'
        },
        {
          title: 'Gift Cards.',
          content: 'Sell gift cards on your previous ecommerce platform? No problem. Clement will import gift cards' +
              ' in a jiffy to Shopify Plus, allowing for a seamless customer experience.'
        },
        {
          title: 'Multi-Channel Support.',
          content: 'Selling Online using Shopify? Selling in person using Shopify POS? Both? Clement will make sure your data is where it needs to be, regardless of where you sell.'
        },
        {
          title: 'Quick, Quality Customer Support.',
          content: 'Our Canadian based support team is here to help you, anytime, any day. Sunday mornings included.'
        },
        {
          title: 'Guaranteed Delivery Date.',
          content: 'Clement understands how important this information is to you. He’s a fast relentless worker with' +
              ' few complaints. When' +
              ' you' +
              ' request a quote, we’ll provide a guaranteed delivery date.'
        },
        {
          title: 'White Glove Treatment.',
          content: 'Need Clement to go the extra mile? Get a dedicated account rep, ongoing support and a plethora of velvety soft services not found on this side of the pond until now with our White Glove Treatment.'
        },
      ],
      info: [
        {
          smtitle: 'Paper napkins included',
          title: 'Multi Channel',
          text: 'Easily import or export ALL your data. Whether your orders and customers live Online, in a POS or in a digital vault in Sumatra. Clement has you covered.',
          image: require('./assets/services/clement-tv2-min.png')
        },
        {
          smtitle: 'Keep it in one basket',
          title: 'Products & Fulfilment',
          text: 'Move your whole basket of goodies in one fell swoop to your new eCommerce platform. Your product catalogue, your fulfillment options, your shipping preferences. No sweat!',
          image: require('./assets/services/clement-eggs-940-min.png')
        },
        {
          smtitle: 'It\'s all about who you know!',
          title: 'Customers',
          text: 'You\'ve spent time and money building loyal customers. Clement will migrate all customer data for' +
              ' you, integrating' +
              ' all of your meta fields, notes, gift cards and other info into your new eCommerce platform so' +
              ' you can spend an extra turn on the merry-go-round.',
          image: require('./assets/services/clement-horse2-min.png')
        }
      ],
      info2: [
        {
          smtitle: 'Build muscle without moving',
          title: 'Doing it in BULK',
          text: 'Take regular and periodic backups of your store. Perform long and arduous product updates  ' +
              ' at the blink of one extra twitchy eye.',
          image: require('./assets/services/clement-hero2-min.png')
        },
        {
          smtitle: 'Don\'t marry a chatbot',
          title: 'White Glove Treatment',
          text: 'Need Clement to go the extra mile? Get a dedicated account rep, ongoing support and a plethora of velvety soft services not found on this side of the pond. ',
          image: require('./assets/services/clement-glove2-min.png')
        },
        {
          smtitle: 'Sundays are not for sulking',
          title: 'Guarantees',
          text: 'Guaranteed delivery dates. Quick, quality customer support. Our Canadian based support team is here to help you, anytime, any day. Sunday mornings included.' +
              ' We guarantee your migration and back it up with any post migration support.',
          image: require('./assets/services/clement-bed2-min.png')
        }
      ]
    },

    retailOnboarding: {
      title: 'Shopify Retail Onboarding',
      title1: 'Switching to Shopify POS?',
      subtitles: ["Congratulations. It appears you’ve decided to switch to Shopify POS. We also think" +
      " this is the best POS" +
      " on the market. Clement is here to help you from the day you sign-up until all of your locations are live," +
      " up and running, and ringing up transactions.", "We will migrate all of your data from your current POS" +
      " system into Shopify, all" +
      " of that" +
      " important" +
      " customer data - what they like to buy, who they like to buy from, what shoe size they wear. Clement has created documentation and training that can be" +
      " tailored for all of" +
      " your staff and delivered remotely or in person.", "We will spend the time to ensure" +
      " your store is running smoother than before and won't leave you hanging like last year's grapes. Clement has" +
      " been fortuitous enough to have worked with many major" +
      " retailers, he will help optimize all of your back office tooling for your next generation Shopify POS" +
      " Experience"],
      title2: 'Sumptuous Support',
      features: [
        {
          title: 'Quick, Quality Customer Support.',
          content: 'Our Canadian based support team is here to help you, anytime, any day. You can call, email,' +
              ' text, send a questionable giphy on slack or do it the old fashioned way get us by Telegram.'
        },
        {
          title: 'Guaranteed Delivery.',
          content: 'Clement understands how important POS migration is to your business. He’s a fast worker' +
              ' and winner of many of Shropshire\'s speed awards.' +
              ' We’ll ensure your data is migrated within a certified - bonafide - guaranteed number of days.'
        },
        {
          title: 'White Glove Treatment.',
          content: 'Need Clement to go the extra mile. We can custom tailor a POS on-boarding package better than a' +
              ' Savile Row bespoke suit fabricator. Give us a ring and see it in action.'
        },
      ],
      info: [
        {
          smtitle: 'Get Ready for Softness',
          title: 'White Glove Treatment',
          text: 'We will spend ALL the time required to ensure your store is running smoother than before and' +
              ' won\'t' +
              ' leave' +
              ' you' +
              ' hanging like last year\'s grapes. That\'s velvet.',
          image: require('./assets/services/clement-tortoise.png')
        },
        {
          smtitle: 'Take it ALL',
          title: 'Move it Move It',
          text: 'We will migrate all of your data from your current POS ' +
              'system into Shopify, all of that important customer data - what they like to buy, who they like to' +
              ' buy from, what shoe size they wear.',
          image: require('./assets/services/clement-box1.png')
        },
        {
          smtitle: 'Shake it all about',
          title: 'Training',
          text: 'Clement has created documentation and training that can be tailored for your business.' +
              ' We can deliver it remotely or in person so your staff can flaunt it like they know it.',
          image: require('./assets/services/clement-hoop.png')
        }
      ],
      info2: [
        {
          smtitle: 'Top Secret',
          title: 'Tailor your POS',
          text: 'Need your POS App customized and optimized to talk to your top secret fulfilment center, back office' +
              ' or ping' +
              ' your secret layer on every purchase? Not a bother.',
          image: require('./assets/services/gavel.png')
        },
        {
          smtitle: 'Turn it up to 11',
          title: 'Dedicated Support',
          text: 'Attain Retail bliss and ascend to POS heaven with a dedicated Account Rep and support that' +
              ' can provide you with quick help when help is needed.',
          image: require('./assets/services/music.png')
        },
        {
          smtitle: 'Go Go Go',
          title: 'Experience You Say',
          text: 'Clement has been fortuitous enough to have worked with many major' +
              ' retailers, he will help optimize all of your back office tooling for your next generation Shopify' +
              ' POS Experience.',
          image: require('./assets/services/trafficlight1.png')
        }
      ]
    },

    shopifyOnlineServices: {
      title: 'Shopify Online Web & Design Services',
      title1: 'Need help with your Shopify Store and Website?',
      subtitles: ["Stand out like Clement’s Trusty Steed and award winning Tweed Jacket with a customized Shopify website design.", "Have the riffraff wow at your storefront and throw money down your money hole post haste."],
      title2: 'What can we do for you:',
      features: [
        {
          title: 'Web Design, Development & User Experience Transfiguration',
          content: "We will create eye-catching, beautiful website experiences that will increase your conversion" +
              " rates and make" +
              " your loved ones terribly proud."
        },
        {
          title: 'Custom Storefront experience',
          content: 'Let Clement and his trusty friends develop an amazing experience for your customers that will' +
              ' make their buying experience one they will share in unusual situations with strangers and bystanders.'
        },
        {
          title: 'Theme Customizations',
          content: 'Already have a Shopify theme? Not a problem. We can customize, refine, optimize and put a' +
              ' distinct shine on your current design and make it pop like a giant jackrabbit stuck in an' +
              ' unusually small box. How peculiar!'
        },
        {
          title: 'Building an omni channel forward website',
          content: 'Combine the convenience of various channels such as retail and online to create a' +
              ' brand accurate personalized' +
              ' experience for your customers.'
        },
      ],
      info: [
        {
          smtitle: 'Digging Deep',
          title: 'Strawberries & Cream',
          text: 'Fancy animations may suit some, richly worded articles may entice others. We dig deep into' +
              ' your brand and target audience to deliver relevant design & content that converts. ',
          image: require('./assets/services/strawberry.png')
        },
        {
          smtitle: 'Don\'t be a cookie',
          title: 'Stand Above the Rest',
          text: 'In a sea of cookie cutter sites we are proud to champion unique eye-catching designs' +
              ' and interactive web experiences that are sure to wow your audience.' ,
          image: require('./assets/services/cookies.png')
        },
        {
          smtitle: 'Customize',
          title: 'Wake the Bear',
          text: 'Already have a theme in mind? We can customize, refine, optimize and put a distinct' +
              ' shine on your current design and make it pop like an unusually plumpy jackrabbit stuck in a small' +
              ' box.  ',
          image: require('./assets/services/bear.png')
        }
      ]
    },

    appDevelopment: {
      title: 'Shopify and Mobile App development',
      title1: 'App Ideas Aplenty',
      subtitles: ["Need a custom solution, specific integration with an ERP, CRM or other back office business" +
      " critical software? Clement can help build that app in the time it takes your thumbs to twiddle twice.", "We’re experienced building both private and public" +
      " apps for Shopify, Apple AppStore and Google PlayStore. If you can't find an app that fits" +
      " your needs do not despair and get in touch. It's more affordable than you may think."],
      info: [
        {
          smtitle: 'Tweed or wool?',
          title: 'Custom Tailored',
          text: 'Find your calling with a Custom private or public Shopify App. Bring joy to your staff by' +
              ' making your Shopify store talk to' +
              ' your ERP, CRM, fancy teapot or other back' +
              ' office' +
              ' system. ',
          image: require('./assets/services/clement-mirror.png')
        },
        {
          smtitle: 'Ideas Ap-plenty',
          title: 'Make it BIG',
          text: 'Got the next revolutionary app idea in mind? We can help bring it to life with' +
              ' full UX design,' +
              ' storyboarding, design, development and testing. So Apron Dancing here we come...' ,
          image: require('./assets/services/clement-apron1.png')
        },
        {
          smtitle: 'Store Equality',
          title: 'All the Stores',
          text: 'Android Play Store, Apple App Store, Shopify POS, Shopify App Store. We don\'t discriminate and' +
              ' treat each app store with the respect it deserves. We can' +
              ' easily handle apps large and small. ',
          image: require('./assets/services/clement-shop1.png')
        }
      ],
      info2: [
        {
          smtitle: 'Build muscle without moving',
          title: 'App Dev 2',
          text: 'Take regular and periodic backups of your store. Perform long and arduous product updates  ' +
              ' at the blink of one extra twitchy eye.',
          image: require('./assets/info/1.png')
        },
        {
          smtitle: 'Don\'t marry a chatbot',
          title: 'White Glove Treatment',
          text: 'Need Clement to go the extra mile? Get a dedicated account rep, ongoing support and a plethora of velvety soft services not found on this side of the pond. ',
          image: require('./assets/info/2.png')
        },
        {
          smtitle: 'Sundays are not for sulking',
          title: 'Guarantees',
          text: 'Guaranteed delivery dates. Quick, quality customer support. Our Canadian based support team is here to help you, anytime, any day. Sunday mornings included.' +
              ' We guarantee your migration and back it up with any post migration support.',
          image: require('./assets/info/3.png')
        }
      ],
    },

    marketing: {
      title: 'Marketing and eCommerce Consulting',
      title2: 'The Best Shops Need to be Found',
      features: ["Branding", "Ads", "Social media campaigns", "Product photography & Videography", "SEO", "Email" +
      " campaigns", "ECommerce Strategy", "Content Creation"],
      info: [
        {
          smtitle: 'Be a Kid',
          title: 'Storybooks',
          text: 'Every campaign should have a story. 200 yards of imagination coupled with a hint of' +
              ' verbal' +
              ' artwork has produced some of the most' +
              ' captivating marketing campaigns this side of a Disney movie.',
          image: require('./assets/services/book1.png')
        },
        {
          smtitle: 'Sing, Dance & Bounce',
          title: 'Videography & Animation',
          text: 'It is scientifically proven that words in the wrong order can be boring and people would rather' +
              ' watch a video. Make your message jump on a trampoline.' ,
          image: require('./assets/services/tortoise.png')
        },
        {
          smtitle: 'Peekaboo',
          title: 'Marketing',
          text: 'Product & Service Marketing Strategy. How quaint! Don\'t just look at the bigger picture,' +
              ' create' +
              ' your own picture.',
          image: require('./assets/services/foto2.png')
        }
      ],
      info2: [
        {
          smtitle: 'No snakes or hats involved',
          title: 'SEO',
          text: 'What good is having the best store in the world if people can\'t find it. We help craft sizzling SEO' +
              ' strategies' +
              ' without the use of magic. ',
          image: require('./assets/services/boiler1.png')
        },
        {
          smtitle: 'Email & Print Campaigns',
          title: 'Keeping Informed',
          text: 'Anyone can come up with an awesome email & print campaign. Dare I say only Clement can do it every' +
              ' week.',
          image: require('./assets/services/unicorn.png')
        },
        {
          smtitle: 'Don\'t Sulk',
          title: 'Business Development',
          text: 'Don\'t wonder the corridors of business aimlessly. Dream Bigger. Let us help you develop new' +
              ' business' +
              ' strategies so you can go on holiday.',
          image: require('./assets/services/business.png')
        }
      ],
    },

    footerData: {
      title: "Clement Labs",
      phone: "+1 (250) 308 6444",
      email: "hello@clementlabs.com",
      btn: "CONTACT US",
      copyright: "© Clement Labs 2019"
    }
  },
  mutations: {

  },
  actions: {

  }
})

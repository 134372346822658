<template>
    <div id="brandLogos">
        <div class="brandLogosContent" :class="{scaleYAnim: (section == 1)}">
            <a class="imageContainer" :href="logoLink1" target="_blank">
                <img :class="{showLogo: logo1}" :src="logoImgs[0].logoImg">
                <img :class="{showLogo: !logo1}" :src="logo1Img.logoImg">
            </a>
            <a class="imageContainer" :href="logoLink2" target="_blank">
                <img :class="{showLogo: logo2}" :src="logoImgs[1].logoImg">
                <img :class="{showLogo: !logo2}" :src="logo2Img.logoImg">
            </a>
            <a class="imageContainer" :href="logoLink3" target="_blank">
                <img :class="{showLogo: logo3}" :src="logoImgs[2].logoImg">
                <img :class="{showLogo: !logo3}" :src="logo3Img.logoImg">
            </a>
            <a class="imageContainer" :href="logoLink4" target="_blank">
                <img :class="{showLogo: logo4}" :src="logoImgs[3].logoImg">
                <img :class="{showLogo: !logo4}" :src="logo4Img.logoImg">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section', 'isMobile', 'logoImgs', 'logoIndex', 'logoOrder', 'logo1', 'logo2', 'logo3', 'logo4', 'logo1Img', 'logo2Img', 'logo3Img', 'logo4Img'],

    computed: {
        logoLink1() {
            return this.logo1 ? this.logoImgs[0].linkToWebsite : this.logo1Img.linkToWebsite
        },
        logoLink2() {
            return this.logo2 ? this.logoImgs[1].linkToWebsite : this.logo2Img.linkToWebsite
        },
        logoLink3() {
            return this.logo3 ? this.logoImgs[2].linkToWebsite : this.logo3Img.linkToWebsite
        },
        logoLink4() {
            return this.logo4 ? this.logoImgs[3].linkToWebsite : this.logo4Img.linkToWebsite
        }
    }
}
</script>

<style lang="sass">

.showLogo
    opacity: .6 !important
    z-index: 150 !important

#brandLogos
    position: relative
    z-index: 100
    margin: 50px auto 10px auto
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    @media (max-width: 600px)
        width: 100vw
        margin: 0 auto
    .brandLogosContent
        transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
        transform: translateY(100%)
        .imageContainer
            width: 8vw
            margin: 1vw
            height: 4vw
            position: relative
            img
                transition: all 1s .1s ease-in-out
                display: block
                position: absolute
                width: 100%
                height: 100%
                opacity: 0
                &:hover
                    transition: all .3s ease-in-out !important
                    opacity: 1 !important
            @media (max-width: 1100px)
                margin: 0 1vw 20px 1vw
                width: 20vw
                height: 9vw
            @media (orientation: landscape) and (max-width: 1320px)
                width: 100px !important
                height: 50px !important
</style>
